export default [
  {
    path: '/services',
    name: 'Services',
    component: () => import('@/views/Services/Index.vue'),
    meta: {
      pageTitle: 'Servis Bakım',
      breadcrumb: [
        {
          text: 'Servis Bakım',
          active: true,
        },
      ],
      resource: 'SERVICES_LIST',
      action: 'read',
    },
  },
  {
    path: '/services/add/1',
    name: 'ServicesAdd1',
    component: () => import('@/views/Services/Add.vue'),
    meta: {
      pageTitle: 'Servis Formu Oluştur',
      breadcrumb: [
        {
          text: 'Servis Bakım',
          to: '/services',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SERVICES_ADD',
      action: 'read',
    },
  },
  {
    path: '/services/add/2',
    name: 'ServicesAdd2',
    component: () => import('@/views/Services/Add.vue'),
    meta: {
      pageTitle: 'Servis Formu Oluştur',
      breadcrumb: [
        {
          text: 'Servis Bakım',
          to: '/services',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'SERVICES_ADD',
      action: 'read',
    },
  },
  {
    path: '/services/edit/:id',
    name: 'ServicesEdit',
    component: () => import('@/views/Services/Edit.vue'),
    meta: {
      pageTitle: 'Servis Formu Güncelle',
      breadcrumb: [
        {
          text: 'Servis Bakım',
          to: '/services',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'SERVICES_EDIT',
      action: 'read',
    },
  },
  {
    path: '/services/view/:id',
    name: 'ServicesView',
    component: () => import('@/views/Services/View.vue'),
    meta: {
      pageTitle: 'Servis Formu Görüntüle',
      breadcrumb: [
        {
          text: 'Servis Bakım',
          to: '/services',
          active: false,
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'SERVICES_VIEW',
      action: 'read',
    },
  },
]
