import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    used_oils: [],
    used_oilsCount: 0,
    used_oil: {},
    used_oilSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getUsed_oils(state) {
      return state.used_oils
    },
    getUsed_oilsCount(state) {
      return state.used_oilsCount
    },
    getUsed_oil(state) {
      return state.used_oil
    },
    getUsed_oilSaveStatus(state) {
      return state.used_oilSave
    },
  },
  mutations: {
    USED_OILS_LIST(state, data) {
      state.used_oils = data
    },
    USED_OIL_VIEW(state, data) {
      state.used_oil = data
    },
    USED_OILS_COUNT(state, data) {
      state.used_oilsCount = data
    },
    USED_OIL_SAVE(state, data) {
      state.used_oilSave = data
    },
  },
  actions: {
    used_oilsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/defines/used_oils', data)
        .then(response => {
          commit('USED_OILS_LIST', response.data.data)
          commit('USED_OILS_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    used_oilView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'used_oils.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/defines/used_oils', data)
        .then(response => {
          commit('USED_OIL_VIEW', response.data.data)
        })
        .catch(error => console.error(error))
    },
    used_oilSave({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/defines/used_oils/saveData', data)
        .then(response => {
          commit('USED_OIL_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
